import React, { ReactNode } from 'react';
import { TooltipContainer } from './style';

interface TooltipProps {
  text: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  children: ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = 'bottom',
  children,
}) => {
  return (
    <TooltipContainer position={position}>
      {children}
      <span className="tooltip">{text}</span>
    </TooltipContainer>
  );
};
