import { FinalizadorasTableProps } from '~/pages/PdvOnline/types';

export const calcRecebido = (
  finalizadoras: FinalizadorasTableProps[],
): number => {
  const somaTotal = finalizadoras.reduce((total, finalizadora) => {
    const valorParcelaString = finalizadora.vlr_parcela;
    let valorFloat: number;

    if (valorParcelaString.length >= 8)
      valorFloat = parseFloat(
        valorParcelaString.replace(/[^\d,]/g, '').replace(',', '.'),
      );
    else valorFloat = parseFloat(valorParcelaString.replace(',', '.')) || 0;

    if (!Number.isNaN(valorFloat)) return total + valorFloat;
    return total;
  }, 0);

  return somaTotal;
};
