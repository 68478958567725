import React, { ReactNode, useState } from 'react';
import { TabButton, TabContainer, TabContent, TabHeader } from './style';

interface TabsProps {
  children: ReactNode;
  initialTab: number;
  bgColor?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  children,
  initialTab = 0,
  bgColor = 'transparent',
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <TabContainer>
      <TabHeader>
        {React.Children.map(children, (child, idx) => {
          if (React.isValidElement(child)) {
            return (
              <TabButton
                key={activeTab}
                isActive={idx === activeTab}
                onClick={() => setActiveTab(idx)}
                id={`tab-${child.props.label}`}
              >
                {child.props.label}
              </TabButton>
            );
          }
          return null;
        })}
      </TabHeader>
      <TabContent bgColor={bgColor}>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child) && index === activeTab) {
            return child.props.children;
          }
          return null;
        })}
      </TabContent>
    </TabContainer>
  );
};
