import React, { useCallback, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { getSaldoCaixa } from '~/pages/PdvOnline/services/getSaldoCaixa';
import { moneyFormat } from '~/utils/functions';
import { TableCell } from '../../style';
import { Content, Dot, HiddenValueWrapper } from './style';

interface CellSaldo {
  value: string | number;
}

export const CellSaldo: React.FC<CellSaldo> = ({ value }) => {
  const { initialStatePdv, handleSaldoPdv } = usePdvOnline();
  const [visibleBalance, setVisibleBalance] = useState(false);

  const handleClickOff = useCallback(async () => {
    setVisibleBalance(!visibleBalance);

    if (!initialStatePdv) return;

    const { saldo } = await getSaldoCaixa(
      Number(initialStatePdv.cod_operador),
      Number(initialStatePdv.num_pdv),
      Number(initialStatePdv.cod_loja),
    );

    handleSaldoPdv(moneyFormat(saldo.toString()));
  }, [handleSaldoPdv, initialStatePdv, visibleBalance]);

  return (
    <TableCell>
      <Content>
        <span style={{ visibility: visibleBalance ? 'visible' : 'hidden' }}>
          {value}
        </span>
        <HiddenValueWrapper
          style={{ visibility: visibleBalance ? 'hidden' : 'visible' }}
        >
          {[1, 2, 3, 4].map((index) => (
            <Dot key={index} />
          ))}
        </HiddenValueWrapper>
        {visibleBalance && (
          <FiEye
            onClick={() => setVisibleBalance(!visibleBalance)}
            aria-label={`mudar visibilidade para ${
              visibleBalance ? 'falso' : 'verdadeiro'
            }`}
            className="icone"
          />
        )}
        {!visibleBalance && (
          <FiEyeOff
            onClick={handleClickOff}
            aria-label={`mudar visibilidade para ${
              visibleBalance ? 'falso' : 'verdadeiro'
            }`}
            className="icone"
          />
        )}
      </Content>
    </TableCell>
  );
};
