import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FinalizadorasTableProps,
  ProdutoProps,
  ProdutoTableProps,
} from '../types';
import {
  ConsultaVendaProps,
  CustomModalProps,
  InitialStatePdv,
  ModalData,
  ModalType,
  PdvOnlineContextData,
  PdvOnlineProps,
  PedidoProps,
  PessoaProps,
  StatusPedido,
} from '../types/context';
import { BuscaVendaParam, FinalizadorasPedidoProps } from '../types/services';
import { resetPessoa } from '../utils/resetPessoa';
import { Context } from '~/context/auth';
import { useForm } from 'react-hook-form';
import { resetAbaProdutos } from '../components/TabProdutos/utils/resetFields';
import { vendasService } from '../services/vendas';
import { nanoid } from 'nanoid';
import { format, parse } from 'date-fns';
import { moneyFormat } from '~/utils/functions';

export const PdvOnlineContext = createContext({} as PdvOnlineContextData);

export const PdvOnlineProvider: React.FC<PdvOnlineProps> = ({ children }) => {
  const [isPdvOpen, setIsPdvOpen] = useState(false);
  const [initialStatePdv, setInitialStatePdv] =
    useState<InitialStatePdv | null>(null);
  const [produtosTable, setProdutosTable] = useState<ProdutoTableProps[]>([]);
  const [isOpenModalFunction, setIsOpenModalFunction] = useState(false);
  const [tabActive, setTabActive] = useState<'PRODUTOS' | 'FINALIZACAO'>(
    'PRODUTOS',
  );
  const [isModalOpen, setIsModalOpen] = useState<CustomModalProps>({
    isOpen: false,
    modal: null,
    data: undefined,
  });
  const [finalizadorasTable, setFinalizadorasTable] = useState<
    FinalizadorasTableProps[]
  >([]);
  const [subTotal, setSubTotal] = useState(0);
  const [resta, setResta] = useState(0);
  const [troco, setTroco] = useState(0);
  const [statusPedido, setStatusPedido] = useState<StatusPedido | null>(null);
  const [pessoa, setPessoa] = useState<PessoaProps | null>(null);
  const [pedido, setPedido] = useState<PedidoProps[]>([]);
  const [finalizadorasPedido, setFinalizadorasPedido] = useState<
    FinalizadorasPedidoProps[]
  >([]);
  const [consultaVendas, setConsultaVendas] = useState<ConsultaVendaProps[]>(
    [],
  );
  const [saldoPdv, setSaldoPdv] = useState('0,00');
  const [infoPedido, setInfoPedido] = useState<{
    cod_seq_pedido: number;
    num_pedido: number;
    tipo_status: number;
  }>({
    cod_seq_pedido: 0,
    num_pedido: 0,
    tipo_status: 0,
  });
  const [isIpv, setIsIpv] = useState(false);
  const [selectProduto, setSelectProduto] = useState<ProdutoProps | null>(null);
  const [filterParamConsultaVenda, setFilterParamConsultaVenda] =
    useState<BuscaVendaParam>();
  const [pdfDanfe, setPdfDanfe] = useState<any>(undefined);
  const [loadingNFCe, setLoadingNFCe] = useState({
    transmitindo: false,
    imprimindo: false,
  });

  const produtoContentRef = useRef<HTMLElement | null>(null);
  const finalizacaoContainertRef = useRef<HTMLElement | null>(null);
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  const { authenticated } = useContext(Context);

  const {
    register: registerTabProduto,
    control: controlTabProduto,
    setValue: setValueTabProduto,
    getValues: getValuesTabProduto,
    reset: resetTabProduto,
    watch: watchTabProduto,
    resetField: resetFieldTabProduto,
    formState: formStateTabProduto,
  } = useForm();

  const formTabProduto = useMemo(() => {
    return {
      registerTabProduto,
      controlTabProduto,
      setValueTabProduto,
      getValuesTabProduto,
      resetTabProduto,
      watchTabProduto,
      resetFieldTabProduto,
      formStateTabProduto,
    };
  }, [
    registerTabProduto,
    controlTabProduto,
    setValueTabProduto,
    getValuesTabProduto,
    resetTabProduto,
    watchTabProduto,
    resetFieldTabProduto,
    formStateTabProduto,
  ]);

  useEffect(() => {
    if (!authenticated) {
      window.location.assign(window.location.origin);
    }
  }, [authenticated]);

  // CASO FUTURAMENTE O PDV PRECISE
  // QUE A AÇÃO DE VOLTAR DO NAVEGADOR
  // SEJA BLOQUEADO, HABILITAR O CÓDIGO ABAIXO
  // useEffect(() => {
  //   const noBackInstance = new NoBack({
  //     version: '0.0.1',
  //     historyApi: typeof window.history.pushState !== 'undefined',
  //   });
  // }, []);

  // CASO FUTURAMENTE O PDV PRECISE
  // SER ABERTO EM TELA CHEIA DE FORMA AUTOMATICA
  // HABILITAR O CÓDIGO  ABAIXO
  // useEffect(() => {
  //   launchFullscreen(document.documentElement);
  // }, []);

  const toggleStatusPdv = useCallback((status: boolean) => {
    setIsPdvOpen(status);
  }, []);

  const handleInitialStatePdv = useCallback((state: InitialStatePdv | null) => {
    setInitialStatePdv(state);
  }, []);

  const onOpenModal = useCallback((modal: ModalType, data?: ModalData) => {
    setIsModalOpen({ isOpen: true, modal, data });
  }, []);

  const onCloseModal = useCallback(() => {
    setIsModalOpen({ isOpen: false, modal: null, data: undefined });
  }, []);

  const handleAddProdutoTable = useCallback(
    (produto: ProdutoTableProps | ProdutoTableProps[]) => {
      if (Array.isArray(produto)) {
        setProdutosTable(produto);
        return;
      }

      setProdutosTable((prev) => [...prev, produto]);
    },
    [],
  );

  const handleChangeOpenModalFunction = useCallback((isOpen: boolean) => {
    setIsOpenModalFunction(isOpen);
  }, []);

  const handleActiveTab = useCallback((tab: 'PRODUTOS' | 'FINALIZACAO') => {
    setTabActive(tab);
  }, []);

  const handleAddFinalizadoraTable = useCallback(
    (finalizadora: FinalizadorasTableProps | FinalizadorasTableProps[]) => {
      if (Array.isArray(finalizadora)) {
        setFinalizadorasTable(finalizadora);
        return;
      }

      setFinalizadorasTable((prev) => [...prev, finalizadora]);
    },
    [],
  );

  const handleAddFinalizadoraPedido = useCallback(
    (
      finalizadoraItem: FinalizadorasPedidoProps | FinalizadorasPedidoProps[],
    ) => {
      if (Array.isArray(finalizadoraItem)) {
        setFinalizadorasPedido(finalizadoraItem);
        return;
      }

      setFinalizadorasPedido((prev) => [...prev, finalizadoraItem]);
    },
    [],
  );

  const handlePedido = useCallback((item: PedidoProps | PedidoProps[]) => {
    if (Array.isArray(item)) {
      setPedido(item);
      return;
    }

    setPedido((prev) => [...prev, item]);
  }, []);

  const handleSubTotal = useCallback((total: number) => {
    setSubTotal(total);
  }, []);

  const onResta = useCallback((value: number) => {
    setResta(value);
  }, []);

  const onTroco = useCallback((value: number) => {
    setTroco(value);
  }, []);

  const handleStatusPedido = useCallback((value: StatusPedido | null) => {
    setStatusPedido(value);
  }, []);

  const handlePessoa = useCallback((value: PessoaProps | null) => {
    setPessoa((prev) => ({ ...prev, ...value }));
  }, []);

  const onConsultaVendas = useCallback((venda: ConsultaVendaProps[]) => {
    setConsultaVendas(venda);
  }, []);

  const handleSaldoPdv = useCallback((saldo: string) => {
    setSaldoPdv(saldo);
  }, []);

  const handleInfoPedido = useCallback(
    (cod_seq_pedido: number, num_pedido: number, tipo_status: number) => {
      setInfoPedido({ cod_seq_pedido, num_pedido, tipo_status });
    },
    [],
  );

  const handleIsIpv = useCallback((value: boolean) => {
    setIsIpv(value);
  }, []);

  const handleSelectProduto = useCallback((selected: ProdutoProps | null) => {
    setSelectProduto(selected);
  }, []);

  const handleFilterParamConsultaVenda = useCallback(
    (param: BuscaVendaParam | undefined) => {
      setFilterParamConsultaVenda(param);
    },
    [],
  );

  const onBuscarVendas = useCallback(
    async (modalOpen: boolean, filterParam: BuscaVendaParam | undefined) => {
      if (!modalOpen || !initialStatePdv) return;

      const { cod_loja } = initialStatePdv;
      const vendas = await vendasService.buscaVenda(Number(cod_loja), {
        params: filterParam,
      });

      if (!vendas || vendas.length === 0) {
        setConsultaVendas([]);
        return;
      }

      const consulta: ConsultaVendaProps[] = vendas.map((v) => ({
        id: nanoid(),
        cod_seq_pedido: v.cod_seq_pedido,
        hra_pedido: format(
          parse(v.hra_pedido, 'HH:mm:ss', new Date()),
          'HH:mm:ss',
        ),
        nome_pessoa: v.nome_pessoa ?? '',
        val_pedido: moneyFormat(v.val_pedido?.toString() ?? '0,00'),
        num_pedido: v.num_pedido,
        des_situacao: v.tipo_status_view,
        tipo_status: v.tipo_status,
        tipo_status_cupom_chave: v.tipo_status_cupom_chave,
        num_pdv: v.num_pdv,
      }));

      setConsultaVendas(consulta);
    },
    [initialStatePdv],
  );

  const handlePdfDanfe = useCallback((buffer: any) => {
    setPdfDanfe(buffer);
  }, []);

  const handleLoadingNFCe = useCallback(
    (transmitindo: boolean, imprimindo: boolean) => {
      setLoadingNFCe({ transmitindo, imprimindo });
    },
    [],
  );

  const handleResetPdv = useCallback(() => {
    setProdutosTable([]);
    setTabActive('PRODUTOS');
    setFinalizadorasTable([]);
    setSubTotal(0);
    setResta(0);
    setTroco(0);
    setPessoa(resetPessoa);
    setPedido([]);
    setFinalizadorasPedido([]);
    setStatusPedido(null);
    setConsultaVendas([]);
    setInfoPedido({ cod_seq_pedido: 0, num_pedido: 0, tipo_status: 0 });
    resetTabProduto(resetAbaProdutos);
    setIsIpv(false);
    setSelectProduto(null);
    setPdfDanfe(undefined);
  }, [resetTabProduto]);

  const contextValue = useMemo(
    () => ({
      isPdvOpen,
      toggleStatusPdv,
      initialStatePdv,
      handleInitialStatePdv,
      handleAddProdutoTable,
      produtosTable,
      produtoContentRef,
      isOpenModalFunction,
      handleChangeOpenModalFunction,
      finalizacaoContainertRef,
      tabActive,
      handleActiveTab,
      isModalOpen,
      onOpenModal,
      onCloseModal,
      finalizadorasTable,
      handleAddFinalizadoraTable,
      handleSubTotal,
      subTotal,
      onResta,
      onTroco,
      resta,
      troco,
      handleStatusPedido,
      statusPedido,
      handlePessoa,
      pessoa,
      handlePedido,
      pedido,
      mainContentRef,
      finalizadorasPedido,
      handleAddFinalizadoraPedido,
      handleResetPdv,
      onConsultaVendas,
      consultaVendas,
      handleSaldoPdv,
      saldoPdv,
      infoPedido,
      handleInfoPedido,
      formTabProduto,
      isIpv,
      selectProduto,
      handleIsIpv,
      handleSelectProduto,
      onBuscarVendas,
      filterParamConsultaVenda,
      handleFilterParamConsultaVenda,
      pdfDanfe,
      handlePdfDanfe,
      loadingNFCe,
      handleLoadingNFCe,
    }),
    [
      consultaVendas,
      finalizadorasPedido,
      finalizadorasTable,
      handleActiveTab,
      handleAddFinalizadoraPedido,
      handleAddFinalizadoraTable,
      handleAddProdutoTable,
      handleChangeOpenModalFunction,
      handleInitialStatePdv,
      handlePedido,
      handlePessoa,
      handleResetPdv,
      handleSaldoPdv,
      handleStatusPedido,
      handleSubTotal,
      initialStatePdv,
      isModalOpen,
      isOpenModalFunction,
      isPdvOpen,
      onCloseModal,
      onConsultaVendas,
      onOpenModal,
      onResta,
      onTroco,
      pedido,
      pessoa,
      produtosTable,
      resta,
      saldoPdv,
      statusPedido,
      subTotal,
      tabActive,
      toggleStatusPdv,
      troco,
      infoPedido,
      handleInfoPedido,
      formTabProduto,
      isIpv,
      selectProduto,
      handleIsIpv,
      handleSelectProduto,
      onBuscarVendas,
      filterParamConsultaVenda,
      handleFilterParamConsultaVenda,
      pdfDanfe,
      handlePdfDanfe,
      loadingNFCe,
      handleLoadingNFCe,
    ],
  );

  return (
    <PdvOnlineContext.Provider value={contextValue}>
      {children}
    </PdvOnlineContext.Provider>
  );
};
