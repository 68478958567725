import { useCallback, useEffect, useState } from 'react';

type UseFullScreenProps = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
};

export const useFullScreen = (): UseFullScreenProps => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenChange = useCallback(() => {
    setIsFullScreen(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    if (document) {
      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () =>
        document.removeEventListener(
          'fullscreenchange',
          handleFullScreenChange,
        );
    }
  }, [handleFullScreenChange]);

  const toggleFullScreen = useCallback(() => {
    if (document.fullscreenEnabled) {
      if (document.fullscreenElement) document.exitFullscreen();
      else document.documentElement.requestFullscreen();
    }
  }, []);

  return { isFullScreen, toggleFullScreen };
};
