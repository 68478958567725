import React from 'react';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { tableHeaderCell } from '../../utils/tableHeaderCell';
import { CellSaldo } from './components/CellSaldo';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableHeaderCell,
  TableRow,
} from './style';

export const TableHeader: React.FC = () => {
  const { initialStatePdv, isPdvOpen, saldoPdv } = usePdvOnline();

  if (!initialStatePdv) return <></>;

  const loja = `${initialStatePdv.des_loja}`;
  const statusPdv = isPdvOpen ? 'ABERTO' : 'FECHADO';
  const valSaldo =
    initialStatePdv.val_saldo === 0 || initialStatePdv.val_saldo === '0'
      ? '0,00'
      : initialStatePdv.val_saldo;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaderCell.map(({ cellName }) => (
              <TableHeaderCell key={cellName}>{cellName}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <tbody>
          <TableRow>
            <TableCell>{loja}</TableCell>
            <TableCell>{initialStatePdv.des_operador}</TableCell>
            <TableCell>{initialStatePdv.num_pdv}</TableCell>
            <TableCell statusPdv={statusPdv}>{statusPdv}</TableCell>
            <CellSaldo value={saldoPdv === '0,00' ? valSaldo : saldoPdv} />
          </TableRow>
        </tbody>
      </Table>
    </TableContainer>
  );
};
