import styled from 'styled-components';

export const TotalizadoresContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

export const CardTotalizadores = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
  border-radius: 5px;

  p {
    font-size: large;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;
