import { useCallback } from 'react';
import { usePdvOnline } from '../usePdvOnline';

interface UseFocusTabProduto {
  handleInputFocus: (elementName: string, click?: boolean) => void;
}

export const useFocusTabProduto = (): UseFocusTabProduto => {
  const { produtoContentRef } = usePdvOnline();

  const handleInputFocus = useCallback(
    (elementName: string, click?: boolean) => {
      if (produtoContentRef.current) {
        const inputElement: HTMLInputElement | null =
          produtoContentRef.current.querySelector(`[name="${elementName}"]`);

        if (inputElement) {
          inputElement.focus();
          if (click) inputElement.click();
          if (inputElement.value && inputElement.type !== 'file')
            inputElement.select();
        }
      }
    },
    [produtoContentRef],
  );

  return { handleInputFocus };
};
