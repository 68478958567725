import React from 'react';
import PdvOnlineContent from './PdvOnlineContent';
import { PdvOnlineProvider } from './contexts/PdvOnlineContext';

const PdvOnline: React.FC = () => {
  return (
    <PdvOnlineProvider>
      <PdvOnlineContent />
    </PdvOnlineProvider>
  );
};

export default PdvOnline;
