import styled from 'styled-components';

export const HiddenValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  left: 0;
`;

export const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #000;
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  user-select: none;

  .icone {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
`;
