import styled from 'styled-components';

export const TooltipContainer = styled.div<{ position: string }>`
  position: relative;
  display: inline-block;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }

  .tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 0.375rem;
    border-radius: 0.25rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
    white-space: nowrap;

    ${(props) => {
      switch (props.position) {
        case 'top':
          return 'bottom: 100%; left: 50%; transform: translateX(-50%);';
        case 'bottom':
          return 'top: 100%; left: 50%; transform: translateX(-50%);';
        case 'left':
          return 'top: 50%; right: 100%; transform: translateY(-50%);';
        case 'right':
          return 'top: 50%; left: 100%; transform: translateY(-50%);';
        default:
          return 'bottom: 100%; left: 50%; transform: translateX(-50%);';
      }
    }}
  }
`;
