import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const TabButton = styled.button<{ isActive: boolean }>`
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  background-color: ${(props) => (props.isActive ? '#9802fe' : '#F3E2FF')};
  color: ${(props) => (props.isActive ? '#fff' : '#333')};
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

export const TabContent = styled.div<{ bgColor?: string }>`
  border: 0.0625rem solid #ddd;
  padding: 1rem;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'transparent'};
  border-radius: 0.5rem;

  @media only screen and (max-width: 1400px) {
    padding: 0 1rem;
  }
`;
