import { useMemo } from 'react';

interface UseRedirectUrl {
  navigateTo: (url: string) => void;
}

export const useRedirectUrl = (): UseRedirectUrl => {
  const navigateTo = useMemo(() => {
    return (url: string) => {
      const checkUrl = url.includes('/') ? url : `/${url}`;
      const appUrl = `${window.location.origin}${checkUrl}`;
      window.location.assign(appUrl);
    };
  }, []);

  return { navigateTo };
};
