import { useCallback } from 'react';
import { usePdvOnline } from '../usePdvOnline';

interface UseFocusTabFinalizacao {
  handleInputFocus: (elementName: string) => void;
}

export const useFocusTabFinalizacao = (): UseFocusTabFinalizacao => {
  const { finalizacaoContainertRef } = usePdvOnline();

  const handleInputFocus = useCallback(
    (elementName: string) => {
      if (finalizacaoContainertRef.current) {
        const inputElement: HTMLInputElement | null =
          finalizacaoContainertRef.current.querySelector(
            `[name="${elementName}"]`,
          );

        if (inputElement) {
          inputElement.focus();
          if (inputElement.value && inputElement.type !== 'file')
            inputElement.select();
        }
      }
    },
    [finalizacaoContainertRef],
  );

  return { handleInputFocus };
};
